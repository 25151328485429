import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as paymentMethodActions from '../../actions/paymentMethods';
import withRouter from '../../utils/withRouter';

import New from "./New";

function PaymentsList(props) {
  const {
    user,
    paymentMethods,
    fetchPaymentMethods,
    paymentMethodId,
    setPaymentMethodId,
    params,
    history
  } = props;

  const [loading, setLoading] = React.useState(true)
  const [newOpen, setNewOpen] = React.useState(false)

  const toggleNewOpen = () => setNewOpen(!newOpen);

  React.useEffect(() => {
    if (paymentMethods.length == 0) {
      fetchPaymentMethods()
        .then(methods => {
          if (methods.length == 0) toggleNewOpen()
        }).finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    if (paymentMethods.length > 0 && paymentMethodId == null) {
      setPaymentMethodId(paymentMethods[0].id)
    }
  }, [paymentMethods])

  return (
    <Box>
      {
        loading ?
          <Loader /> :
          <Box>
            <Stack spacing={1}>
              {
                _.map(paymentMethods, method => {
                  return (
                    <ClickablePaper key={method.id} selected={
                      paymentMethodId == method.id
                    } onClick={() => setPaymentMethodId(method.id)}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Typography variant="body1" fontWeight={600}>
                            {_.capitalize(method.card.brand)}
                          </Typography>
                          <Typography variant="body2">
                            Ending in {method.card.last4}
                          </Typography>
                        </Box>

                        <ArrowForwardIosIcon />
                      </Stack>
                    </ClickablePaper>
                  )
                })
              }
            </Stack>
            <Box mt={2}>
              {
                newOpen ?
                  <New onSubmit={fetchPaymentMethods} /> :
                  <Button startIcon={<AddIcon />} fullWidth variant="outlined" onClick={toggleNewOpen} size="large">
                    Add payment method
                  </Button>

              }
            </Box>
          </Box>
      }


    </Box>
  )
}


const select = $$state => _.pick($$state, ["user", "paymentMethods"]);
export default connect(select, {
  ...paymentMethodActions,
})(withRouter(PaymentsList));
