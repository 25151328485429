import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import { ChevronRight } from 'lucide-react';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Iconify from '../../components/iconify';
import Alert from '../../components/Alert';

import withRouter from '../../utils/withRouter';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';
import { fCurrency } from '../../utils/format-number';
import * as dates from '../../utils/dates';

import ClickablePaper from '../../components/ClickablePaper';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import Card from "./Card";

function CardsList(props) {
  const { cards, activeCard, setActiveCard, history } = props;

  return (
    <Stack spacing={1}>
      {cards.map((card) => (
        <Box          key={card.id}
        >
          <ClickablePaper
            onClick={() => {
              history.push(`./${card.id}`)
            }}
            variant="outlined"
            selected={card.id == activeCard.id}
            sx={{
              p: 4,
            }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                {
                  card.source == "plaid" ?
                    <Typography variant="body2">{card.name}</Typography> :
                    <Iconify width={50} icon="logos:visa" />

                }
              </Box>

              <ChevronRight />
            </Stack>


            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                {
                  card.source == "gravy" &&
                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        {
                          fCurrency(card.amount)
                        }
                      </Typography>
                      <Typography variant="body3">
                        Created {dates.tzAwareDateTime(card.created_at, "MM/DD/YY")}
                      </Typography>
                    </Box>

                }
              </Box>

              <Typography variant="body2">{
                `******${card.last_4}`
              }</Typography>
            </Stack>

          </ClickablePaper>
        </Box>
      ))}
    </Stack>
  )
}

const CardsMain = props => {
  const {
    user,
    cards,
    fetchCards,
    savePlaidUser,
    merchantOffers
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [activeCard, setActiveCard] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const sortedCards = _.chain(cards)
    .filter(card => card.source == "gravy")
    .value()

  React.useEffect(() => {
    if (cards.length > 0) setActiveCard(
      _.get(sortedCards, "0", {})
    )
  }, [cards.length])

  const connectToPlaid = async token => {
    try {
      setSaving(true)
      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.href = "/app/account/cards/"
      }
    } catch(err) {
      console.log(err)
      alert("Unknown error please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box pb={10}>
      <Container maxWidth="sm">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant={"h5"} fontWeight={600}>
            Your Gravy Visa Cards
          </Typography>
          <Button startIcon={
            <AddIcon />
          } component={RouterLink} to="./gravy" color="primary" variant="contained">
            Buy Card
          </Button>
        </Stack>

        <Box mt={2}>
          <CardsList
            {...props}
            cards={sortedCards}
            activeCard={activeCard}
            setActiveCard={setActiveCard} />
        </Box>
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "cards", "merchantOffers"]);
export default connect(select, {
  ...cardActions,
  ...userActions
})(withRouter(CardsMain));
