import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function InstructionsPour(props) {
  const { merchant } = props;

  const imageUrl = {
    Chipotle: "https://p70.p4.n0.cdn.zight.com/items/P8uO4JK1/b7240eff-3c6a-4ec4-85ec-8af0daec8fa5.png?source=viewer&v=974aedb8658bb7129333df14e50947ba",
    DoorDash: "https://p70.p4.n0.cdn.zight.com/items/o0uAwWX5/1f90654b-2c0f-4228-91f1-243e82fc63fd.png?source=viewer&v=dd8e1a2f4caa8968adc6b7d2760aa0b0",
    Uber: "https://p70.p4.n0.cdn.zight.com/items/YEub0Yqo/346de590-aec0-4416-b946-40705f18d29b.png?source=viewer&v=16282c3dfa7a58cf4bb1ee50fcf9a938",
    Starbucks: "https://p70.p4.n0.cdn.zight.com/items/NQu9PQpG/485e3003-2f22-4e49-b602-63520fe84470.png?source=viewer&v=234952bd4c5969e8dafcda51d86beb5c"
  }[merchant.name]

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={600}>
          Spending your cash is EASY
        </Typography>

        <Typography variant="body1" fontWeight={400}>
          Pay with your balance in the {merchant.name} app.
        </Typography>
      </Box>

      <Box mt={1}>
        <img
          src={imageUrl}
          style={{
            width: "100%"
          }} />
      </Box>
    </Box>
  );
}
