import _ from "lodash";
import qs from 'qs';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Soup, Zap, ChartColumnIncreasing } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import TextField from '../../components/TextField';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as payoutActions from '../../actions/payouts';
import * as alertActions from '../../actions/alerts';
import * as integrationActions from '../../actions/integrations';
import { fShortenNumber } from '../../utils/format-number';

import OrdersNew from '../orders/New';

function ShopForm(props) {
  const { user, location } = props;

  const [open, setOpen] = React.useState(0);
  const toggleOpen = () => setOpen(!open);

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );


  const gift = !!search.gift;

  const validationSchema = gift && yup.object({
    amount: yup
      .number(),
    user_name: yup
      .string(),
    name: yup
      .string()
      .required(),
    email: yup
      .string(),
    phone: yup
      .string()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      gift,
      amount: "50",
      name: "",
      email: "",
      phone: "",
      user_name: user.name
    },
    validationSchema,
    onSubmit: () => {
      toggleOpen()
    }
  });

  const amounts = [25, 50, 75, 100, 125, 150, 175, 200];

  return (
    <Box>

      <form onSubmit={formik.handleSubmit} id="recipient-form">
        <Stack spacing={2}>

          <Box mb={2}>
            <ButtonGroup sx={{mt: 1}} fullWidth size="large">
              <Button fullWidth variant={
                formik.values.gift ? "contained" : "outlined"
              } onClick={() => {
                formik.setFieldValue("gift", true)
                formik.setFieldValue("name", "")
                formik.setFieldValue("phone", "")
              }}>
                Gift
              </Button>

              <Button fullWidth variant={
                !formik.values.gift ? "contained" : "outlined"
              } onClick={() => {
                formik.setFieldValue("gift", false)
                formik.setFieldValue("name", "User")
                formik.setFieldValue("phone", "1")
              }}>
                For ME
              </Button>
            </ButtonGroup>
          </Box>

          <Box>
              <Stack spacing={0.5}>
                <ButtonGroup fullWidth size="large">
                  {
                    _.chain(amounts)
                      .slice(0, 4)
                      .map(a =>
                        <Button key={a} fullWidth variant={a == formik.values.amount ? "contained" : "outlined"} onClick={() =>
                          formik.setFieldValue("amount", a)
                        }>
                          ${a}
                        </Button>
                      ).value()
                  }
                </ButtonGroup>
                <ButtonGroup fullWidth size="large">
                  {
                    _.chain(amounts)
                      .slice(4, amounts.length)
                      .map(a =>
                        <Button key={a} fullWidth variant={a == formik.values.amount ? "contained" : "outlined"} onClick={() =>
                          formik.setFieldValue("amount", a)
                        }>
                          ${a}
                        </Button>
                      ).value()
                  }

                </ButtonGroup>
              </Stack>
            </Box>

            {
              formik.values.gift &&
                <Stack spacing={1}>
                  {
                    !!user.name ||
                      <TextField
                        fullWidth
                        name="user_name"
                        label="Your name"
                        value={formik.values.user_name}
                        onChange={formik.handleChange}
                        error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                        helperText={formik.touched.user_name && formik.errors.user_name} />
                  }

                  <TextField
                    fullWidth
                    name="name"
                    label="Recipient Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name} />

                  <TextField
                    fullWidth
                    name="phone"
                    label="Recipient Phone #"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone} />

                  <TextField
                    fullWidth
                    name="email"
                    label="Recipient Email (Optional)"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email} />
                </Stack>
            }
        </Stack>


        <OrdersNew
          {...props}
          {...formik.values}
          recipient={formik.values}
          open={open}
          onClose={toggleOpen} />


      </form>
    </Box>
  )
}


function PurchaseForm(props) {
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: props.onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} id="recipient-form">
    </form>
  )
}

function ShopDetails(props) {
  const {
    user,
    merchantOffers,
    createAlert,
    createPayout,
    params,
    history,
    location
  } = props;

  const onClose = () => {
    history.goBack()
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h2" fontWeight={600}>
            Buy Gravy Visa Card
          </Typography>
           <Typography variant="h4" fontWeight={400} lineHeight={1.2}>
            Up to 100% back per swipe when cool things happen in the sports and culture.
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent>


      <ShopForm {...props} />

      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        <Button variant="contained" type="submit" form="recipient-form">
          Next
        </Button>

      </DialogActions>

    </FullScreenDialog>

  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "integrations", "points"]);
export default connect(select, {
  ...alertActions,
  ...integrationActions,
  ...alertActions,
  ...merchantOffersActions,
  ...payoutActions,
})(withRouter(ShopDetails));
