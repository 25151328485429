import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { CreditCard, Gift, Activity, Camera, Soup, ChevronRight } from 'lucide-react';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as transactionActions from '../../actions/transactions';

import PlaidLogo from '../../icons/PlaidLogo';

import PourList from '../pour/List';
import Instructions from './Instructions';

const DashboardHome = props => {
  const {
    user,
    feedItems,
    picks,
    transactions,
    fetchTransactions,
    params,
    history,
    location,
    teams
  } = props;

  React.useEffect(() => {
    // Scroll to the top of the window on first render
    window.scrollTo(0, 0);

  }, []);

  return (
    <Box pb={12} sx={{background: "#f5f5f5"}}>
      <Container maxWidth="sm">
        <Instructions />

          {
            false &&
                <Alert color="secondary" sx={{
                  border: "1px solid #c9b1f0"
                }} action={
                  <Button variant="contained" color="secondary" size="small" component={RouterLink} to="/app/account/referrals/new">
                    Details
                  </Button>
                }>
                  <Typography variant="body2" fontWeight={600} color="secondary">
                    Refer friends, Win $100 Gift Cards
                  </Typography>
                </Alert>

          }

        <Box mb={3}>
          <Typography variant="h6" fontWeight={500}>
            Save <b>BIG</b> everywhere
          </Typography>

          <Box mt={1}>
            <Stack spacing={1}>
              <ClickablePaper onClick={() => {
                history.push(`/app/cards/new`)
              }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar sx={{
                      background: "#4a5dff"
                    }}>
                      <CreditCard />
                    </Avatar>

                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        Buy Visa Card
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Up to 100% cash back
                      </Typography>
                    </Box>
                  </Stack>

                  <ChevronRight />
                </Stack>
              </ClickablePaper>

              <ClickablePaper onClick={() => {
                history.push(`/app/cards/new?gift=true`)
              }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar sx={{
                      background: "#141c3f"
                    }}>
                      <Gift />
                    </Avatar>

                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        Gift Visa Card
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Up to 100% cash back
                      </Typography>
                    </Box>
                  </Stack>

                  <ChevronRight />

                </Stack>
              </ClickablePaper>

            </Stack>
          </Box>
        </Box>

        <Divider />

        <Box mt={3}>
          <Typography variant="h6" fontWeight={500}>
            Save <b>BIG</b> on popular apps
          </Typography>

          <Box mt={1}>
            <PourList />
          </Box>
        </Box>
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "feedItems", "teams", "picks", "transactions"]);
export default connect(select, {
  ...transactionActions,
  ...userActions,
})(withRouter(DashboardHome));
